import React from 'react'
import './skill.css'

function Skill() {
    return (
        <div className="main-skill" id="main-skill">
            <div className="skill">
                <h2 className="tittle">My skills</h2>
                <div className="skill-content">
                    <div className="column left">
                        <div className="text">My creative skills & experiences.</div>
                        <p >Full Stack developer , with high self-learning abilities with development experience in
                        Proven experience in routine work under stress conditions while looking at the small details.
                        <span style={{"float":"left","margin":"10px 0 0 0"}}>I have experience <span style={{"font-weight":"600"}}>3 Months</span>
                             &nbsp; as MERN Stack developer , I work with </span>
                            <ul style={{"width":"100%","display":"block","float":"left","list-style":"circle"}}>
                                <li style={{"width":"50%","float":"left"}}> React.js</li>
                                <li style={{"width":"50%","float":"left"}}> Node.js</li>
                                <li style={{"width":"50%","float":"left"}}>Express.js</li>
                                <li style={{"width":"50%","float":"left"}}> MongoDB</li>
                            </ul>
                             <span style={{"float":"left"}}>I have experience <span style={{"font-weight":"600"}}>1 Year</span>
                             &nbsp; as Full Stack developer , I work with </span>
                             
                             <ul style={{"width":"100%","display":"block","float":"left","list-style":"circle"}}>
                                <li style={{"width":"50%","float":"left"}}>PHP</li>
                                <li style={{"width":"50%","float":"left"}}>CSS</li>
                                <li style={{"width":"50%","float":"left"}}>HTML</li>
                                <li style={{"width":"50%","float":"left"}}>JavaScript</li>
                                <li style={{"width":"50%","float":"left"}}>Ajax</li>
                                <li style={{"width":"50%","float":"left"}}>JQuery</li>

                                <li style={{"width":"50%","float":"left"}}>MySql</li>

                            </ul>
                        </p>
                    </div>
                    <div className="column right">

                        <div className="bars">
                            <div className="info">
                                <span>React.js </span>
                                <span>95%</span>
                            </div>
                            <div className="line react"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>Express.js </span>
                                <span>95%</span>
                            </div>
                            <div className="line express"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>Angular </span>
                                <span>85%</span>
                            </div>
                            <div className="line angular"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>PHP </span>
                                <span>98%</span>
                            </div>
                            <div className="line php"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>JavaScript </span>
                                <span>98%</span>
                            </div>
                            <div className="line js"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>HTML </span>
                                <span>95%</span>
                            </div>
                            <div className="line html"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>CSS </span>
                                <span>95%</span>
                            </div>
                            <div className="line css"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>MySQL </span>
                                <span>95%</span>
                            </div>
                            <div className="line mysql"></div>
                        </div>

                        <div className="bars">
                            <div className="info">
                                <span>Mongo DB </span>
                                <span>95%</span>
                            </div>
                            <div className="line mongo"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill
