import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/navbar/Navbar'
import Home from './components/home/Home';
import About from './components/about/About';
import Projects from './components/projcts/Projects';
import Contact from './components/contact/Contact';
import Skill from './components/skills/Skill';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function App() {
  const [upBtn, setUpBtn] = useState(false);
  function showHideUpBtn() {
      if (document.documentElement.scrollTop > 30) {
        setUpBtn(true);
      } else {
        setUpBtn(false);
      }
  }

  window.addEventListener('scroll', showHideUpBtn);
  // const[views,setViews]=useState(0);
//   useEffect(() => {
//     axios.post('http://localhost:8080/user/check',{proj:"portfolio"})
//     .then(res => {
//         setViews(res.data.visits);
//     })
// }, []); 
  return (
    <div className="App">
      <div className={`scroll-up-btn ${upBtn?"":"hide"}`}>
        <ExpandLessIcon className="up-btn" onClick={()=>document.querySelector('#home').click()}/>
      </div>
      <Router>
        <Navbar/>
        <Switch>
          <Route exact path="/"><Redirect to="/home" /></Route>
          <Route path='' component={() => <Home />} />
          <Route path='' component={() => <About />} />
          <Route path='' component={() => <Projects />} />
          <Route path='' component={() => <Contact />} />
        </Switch>
        <About/>
        <Projects/>
        <Skill/>
        <Contact/>
        {/* <div>{views}</div> */}
      </Router>
    </div>
  );
}

export default App;
