import React, { useState } from 'react'
import './navbar.css';

function Navbar() {
    const [a, setA] = useState(true);
    function ddd() {
        if (document.documentElement.scrollTop > 1) {
            setA(false);
        } else {
            setA(true);
        }
    }
    window.addEventListener('scroll', ddd);

    let toggleNav = () => { document.querySelector('.to-hide-ul').classList.toggle('showNavbar')};

    let toggle2=()=>{
        if(document.querySelector('.to-hide-ul')){//prevent the error of compiling 
            let element2=document.querySelector('.to-hide-ul');
            if(document.querySelector('.to-hide-ul').classList.contains('showNavbar')){
                document.querySelector('.to-hide-ul').classList.toggle('showNavbar');
            }
        }
        return true;
    }
    return (
        <div className={`main-navbar ${a ? "" : "navbar2"}`}>
            <div className="navbar">
                <div className="portfolio" onClick={()=>{window.location="/"}}>
                    Portfo<span className={`lio ${a ? "" : "lio2"}`}>lio</span>
                </div>
                <div>
                    <div><span onClick={toggleNav} class="toggleNavbar" id="toggleNavbar" to={window.location}>☰</span></div>
                    <div className="to-hide-ul">
                        <ul className={`MyUl`}>
                            <li ><a onClick={toggle2} id="home" className={`${a ? "" : "black-color"}`} href="#main-home" onCLick={toggle2()}>Home</a></li>
                            <li ><a onClick={toggle2} href="#main-about" >About</a></li>
                            <li ><a onClick={toggle2} href="#main-projects" >Projects</a></li>
                            <li ><a onClick={toggle2} href="#main-skill" >Skills</a></li>
                            <li ><a onClick={toggle2} href="#main-contact" >Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
