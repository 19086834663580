import React from 'react';
import './contact.css';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RoomIcon from '@material-ui/icons/Room';
import emailjs from 'emailjs-com';

export default function Contact() {
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_4u87tb8', 'template_4ytz99c', e.target, 'user_T3KxrPmmTdvqCaojsKkPx')
          .then((result) => {
              console.log(result.text);
              e.target.reset()
          }, (error) => {
              console.log(error.text);
          });
      }
    return (
        <div className="main-contact" id="main-contact">
            <div className="contact">
                <h2 className="tittle">Contact me</h2>
                <div className="contact-content">
                    <div className="column left">
                        <div className="text">Get in Touch</div>
                        <p>I promise that you won't be disappointed , don't hesitate to call me or message me .</p>

                        <div className="icons">{/* for each icon/row */}
                            <PersonIcon />
                            <div className="info">
                                <div className="head">Name</div>
                                <div className="sub-title">Ofer Bakria</div>
                            </div>
                        </div>

                        <div className="icons">{/* for each icon/row */}
                            <MailOutlineIcon />
                            <div className="info">
                                <div className="head">Email</div>
                                <div className="sub-title">ofer.bakria@gmail.com</div>
                            </div>
                        </div>

                        <div className="icons">{/* for each icon/row */}
                            <RoomIcon />
                            <div className="info">
                                <div className="head">Location</div>
                                <div className="sub-title">Peqiin 930 , Israel</div>
                            </div>
                        </div>

                    </div>
                    <div className="column right">
                        <div className="text">Message me</div>
                        <form onSubmit={sendEmail}>
                            <div className="fields">

                                <div className="field name">
                                    <input type="text" autoComplete="off" placeholder="Your Name" name="name" required />
                                </div>

                                <div className="field email">
                                    <input type="text" autoComplete="off" placeholder="Your Email" name="email" required />
                                </div>
                            </div>
                            <div className="field subject">
                                <input type="text" autoComplete="off" placeholder="Subject" name="subject" required />
                            </div>

                            <div className="field textarea">
                                <textarea cols="30" rows="10" autoComplete="off" placeholder="Describe" name="describe" required />
                            </div>

                            <div className="field button">
                                <button type="submit">Send message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
