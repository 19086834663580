import React from 'react';
import './about.css';

function About() {
    return (
        <div className="main-about" id="main-about">
            <div className="about">
                <h2 className="tittle">About Me</h2>
                <div className="left-right-div">
                    <div className="column left">
                        <img src="man.svg" alt="pic-for-me" />
                    </div>
                    <div className="column right">
                        <p>
                        <p className="text">I'm Ofer Bakria and I'm a <span style={{"color":"crimson"}}>FullStack Developer</span>.</p>
                        Practical Software Engineer with high self-learning abilities with development experience in 
                        React.js, Express.js, PHP, Angular, TypeScript, JavaScript, Css, Html, JavaScript, SQL And with Mongo DB.
                         Proven experience in routine work under stress conditions while looking at the small details. Also, creating team work interfaces to 
                         achieve goals and targets set by company management. Decisive, determined to achieve the goal with great motivation to 
                         learn new fields and succeed by implementing My studies.
                        </p>
                        <a href="Ofer's-Resume.pdf" target="_blank">See my CV</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
