import React from 'react';
import './projects.css';
import CodeIcon from '@material-ui/icons/Code';

export default function Projects() {
    return (
        <div className="main-projects" id="main-projects">
            <div className="projects">
                <h2 className="tittle">My Projects</h2>
                <div className="all-pro-cards">

                    <div className="card">{/* for each one of my projects */}
                        <div className="card-title"><CodeIcon className="code-icon"/><br/><span>Robots Game</span></div>
                        <p>HTML,CSS,JS using robo api to get robots images , find all duplicate robots in hide image mode.</p>
                        <a href="https://github.com/oferbakria/robots-game" className="a-code">Code</a>
                        <a href="https://robots-game-heroku.herokuapp.com/" className="a-online">Online</a>
                    </div>

                    <div className="card">{/* for each one of my projects */}
                        <div className="card-title"><CodeIcon className="code-icon"/><br/><span>My-Gallery</span></div>
                        <p>React.js , Express.js ,MongoDb using Pixabay api to get photos , save photos for each user in personal gallery</p>
                        <a href="https://github.com/oferbakria/my-gallery" className="a-code">Code</a>
                        <a href="https://ofer-b.co.il/my-gallery/" className="a-online">Website</a>
                    </div>

                    <div className="card">{/* for each one of my projects */}
                        <div className="card-title"><CodeIcon className="code-icon"/><br/><span>Lecturer-Student App</span></div>
                        <p>React.js ,Express.js, MongoDB , Material/icons , JWT for security , app for Attendance management .</p>
                        <a href="https://github.com/oferbakria/lecturer-student-app" className="a-code">Code</a>
                        <a href="https://lecturer.ofer-b.co.il/" className="a-online">Website</a>
                    </div>

                    <div className="card">{/* for each one of my projects */}
                        <div className="card-title"><CodeIcon className="code-icon"/><br/><span>Catalog App</span></div>
                        <p>PHP , Html , CSS , JS ,  app for business Catalog , Website And BackOffice for Management . </p>
                        <a href="https://ofer-b.co.il/catalog/admin.php" className="a-code">Back Office</a>
                        <a href="https://ofer-b.co.il/catalog" className="a-online">Website</a>
                    </div>

                </div>
            </div>
        </div>
    )
}
