import React, { useEffect } from 'react'
import './home.css';
import Typical from 'react-typical';

export default function Home() {
    useEffect(() => {
    }, []);

    return (
        <div className="main-home" id="main-home">
            <div className="home">
                <div className="text1">Hello,my name is </div>
                <div className="text2">Ofer Bakria </div>
                <div className="text3">And I'm 
                <span>
                    <Typical
                    loop={Infinity}
                    wrapper="b"
                    steps={[
                    ' Web Developer',
                    2000,
                    ' React Developer',
                    2000,
                    ' Full Stack Developer',
                    3000,
                    ' PHP Developer',
                    2000,
                    ]}
                    />
                </span>
                </div>
                    <a href="Ofer's-Resume.pdf" download>Download CV</a>
            </div>
        </div>
    )
}
